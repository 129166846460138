import {
  Button,
  Checkbox,
  Col,
  Divider,
  Layout,
  Progress,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Icon,
  Alert,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Layout);
Vue.use(Table);
Vue.use(Row);
Vue.use(Col);
Vue.use(Divider);
Vue.use(Select);
Vue.use(Tooltip);
Vue.use(Tag);
Vue.use(Progress);
Vue.use(Icon);
Vue.use(Alert);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
