<template>
  <a-layout id="components-layout" style="min-height: 100vh">
    <a-row>
      <a-col :sm="16">
        <h2 class="title">COVID-19 Vaccine Slots Availability</h2>
      </a-col>
      <!-- <a-col :sm="8" align="right">
        <a-button class="tocowin" type="primary">
          <a href="https://selfregistration.cowin.gov.in/" target="_blank"
            >Register / Sign In yourself</a
          >
        </a-button>
      </a-col> -->
    </a-row>
    <a-divider class="divider top" />

    <a-row :gutter="[16, 16]">
      <a-col :xs="24" :sm="8" :md="8" :lg="8">
        <a-row type="flex" align="middle">
          <a-col :xs="24" :sm="12" :md="8" :lg="8">
            <b>Select State</b>
          </a-col>
          <a-col :xs="24" :sm="12" :md="16" :lg="16">
            <a-select
              show-search
              v-model="formData.state"
              option-filter-prop="children"
              :filter-option="filterState"
              placeholder="Select State"
              style="width: 90%"
              @change="stateChange"
            >
              <a-select-option
                v-for="state in stateList"
                :key="state.state_id"
                :value="state.state_id"
                >{{ state.state_name }}</a-select-option
              >
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :sm="8" :md="8" :lg="8">
        <a-row type="flex" align="middle" v-show="showDistrict">
          <a-col :xs="24" :sm="12" :md="8" :lg="8">
            <b>Select District</b>
          </a-col>
          <a-col :xs="24" :sm="12" :md="16" :lg="16">
            <a-select
              show-search
              v-model="formData.district"
              option-filter-prop="children"
              :filter-option="filterDistrict"
              placeholder="Select District"
              style="width: 90%"
              @change="districtChange"
            >
              <a-select-option
                v-for="district in districtList"
                :key="district.district_id"
                :value="district.district_id"
                >{{ district.district_name }}</a-select-option
              >
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :sm="8" :md="8" :lg="8">
        <a-row type="flex" align="middle" justify="end" v-show="showRefreshing">
          <a-col :xs="24" :sm="24" :md="24" :lg="24" class="text-right">
            <b>Refreshing in </b>
            <!-- progress -->
            <a-progress
              type="circle"
              :percent="counter * 20"
              :width="30"
              :counter="counter"
              :strokeColor="'#87d068'"
            >
              <template #format="counter">
                <span style="color: #87d068">{{ counter / 20 }}</span>
              </template>
            </a-progress>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-divider class="divider" />

    <a-row>
      <a-col :xs="24" :sm="12" :md="12" :lg="12">
        <b>Filter By : </b>
        <a-tag
          class="tag"
          :color="formData.ageOne ? '#fa8c16' : ''"
          @click="updateAgeFilter('18')"
        >
          Age 18+
        </a-tag>
        <a-tag
          class="tag"
          :color="formData.ageTwo ? '#36b7f5' : ''"
          @click="updateAgeFilter('45')"
        >
          Age 45+
        </a-tag>
        <a-divider type="vertical" />&nbsp;
        <a-tag
          :color="formData.covishield ? '#08ad2a' : ''"
          @click="updateVaccineFilter('COVISHIELD')"
        >
          COVISHIELD
        </a-tag>
        <a-tag
          :color="formData.covaxin ? '#35c8ff' : ''"
          @click="updateVaccineFilter('COVAXIN')"
        >
          COVAXIN
        </a-tag>
        <a-tag
          :color="formData.sputnikv ? '#722ed1' : ''"
          @click="updateVaccineFilter('SPUTNIKV')"
        >
          SPUTNIK-V
        </a-tag>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" class="text-right">
        <a-checkbox v-model="formData.playSound"> Play sound </a-checkbox>
      </a-col>
    </a-row>
    <a-divider class="divider" />
    <a-row>
      <a-col :xs="24">
        <!-- <b>Note:</b> -->
        <a-alert
          message="No need to scroll, System will display available slots on top in green color as soon as they are available. D1: Dose 1, D2: Dose 2"
          type="info"
          banner
        />
        <!-- <a-alert
          message="For latest updates, just do hard reload by clicking Ctrl + F5."
          type="info"
          banner
        /> -->
      </a-col>
    </a-row>
    <a-divider class="divider" />
    <a-layout-content
      :style="{
        background: '#fff',
      }"
    >
      <a-row :gutter="16">
        <a-col>
          <a-table
            :columns="columns"
            :data-source="filteredSlots"
            :pagination="false"
            :row-key="(record) => record.center_id"
            size="small"
          >
            <div slot="pincode" slot-scope="text, record">
              <b>{{ record.pincode }}</b>
              <br />
              <a-tag class="smallTags" :title="record.fee_type" color="#5300ff">
                {{ record.fee_type }}
              </a-tag>
            </div>

            <div
              v-for="day in daySlots"
              :key="day.index"
              :slot="'day' + day.index"
              slot-scope="text, record"
            >
              <a-tag
                v-if="
                  _get(record, `day${day.index}Vaccine`) === '' &&
                  _get(record, `day${day.index}Slots`) === ''
                "
                color="#387eff"
                title="Not Available"
                class="smallTags"
              >
                N/A
              </a-tag>
              <div
                v-else
                v-for="vac in _get(record, ['vaccine', day.date], '-')"
                :key="vac.key"
              >
                <a-tag
                  v-if="
                    (_get(record, `day${day.index}Vaccine`) !== '' &&
                      _get(record, `day${day.index}Slots`) > 0) ||
                    (_get(vac, `vaccine`) !== '' &&
                      _get(vac, `available_capacity`) > 0)
                  "
                  :color="
                    _get(record, `day${day.index}Slots`) ? '#87d068' : '#f50'
                  "
                  title="Slots Available"
                  class="smallTags"
                >
                  <a
                    href="https://selfregistration.cowin.gov.in/"
                    target="_blank"
                    class="vaccineAvailable"
                  >
                    {{
                      _get(
                        vac,
                        `available_capacity`,
                        _get(record, `day${day.index}Slots`)
                      )
                    }}
                    :
                    {{
                      _get(
                        vac,
                        `vaccine`,
                        _get(record, `day${day.index}Vaccine`)
                      )
                    }}
                  </a>
                </a-tag>

                <a-tag
                  v-if="
                    _get(record, `day${day.index}Vaccine`) !== '' &&
                    _get(record, `day${day.index}Slots`) === 0
                  "
                  color="#ff4400"
                  title="Fully Booked"
                  class="smallTags"
                >
                  Booked : {{ _get(record, `day${day.index}Vaccine`) }}
                </a-tag>

                <div>
                  <a-tag
                    class="smallTags"
                    v-if="_get(vac, `vaccine`) !== ''"
                    :color="
                      _get(
                        vac,
                        `min_age_limit`,
                        _get(record, `day${day.index}AgeLimit`)
                      ) === 45
                        ? '#2db7f5'
                        : '#fa8c16'
                    "
                    title="Age Limit"
                  >
                    {{
                      _get(
                        vac,
                        `min_age_limit`,
                        _get(record, `day${day.index}AgeLimit`)
                      )
                    }}+
                  </a-tag>

                  <a-tag
                    class="smallTags"
                    v-if="_get(vac, `available_capacity_dose1`, 0) !== ''"
                    title="Dose 1"
                    color="purple"
                  >
                    D1: {{ _get(vac, `available_capacity_dose1`, 0) }}
                  </a-tag>

                  <a-tag
                    class="smallTags"
                    v-if="_get(vac, `available_capacity_dose2`, 0) !== ''"
                    title="Dose 2"
                    color="purple"
                  >
                    D2: {{ _get(vac, `available_capacity_dose2`, 0) }}
                  </a-tag>
                </div>

                <a-tag
                  v-if="
                    _get(
                      vac,
                      `vaccine`,
                      _get(record, `day${day.index}Vaccine`)
                    ) === '' &&
                    _get(vac, `slots`, _get(record, `day${day.index}Slots`)) ===
                      ''
                  "
                  color="#387eff"
                  title="Not Available"
                  class="smallTags"
                >
                  N/A
                </a-tag>
              </div>
              <!-- <a-tag
                v-if="
                  _get(record, `day${day.index}Vaccine`) !== '' &&
                  _get(record, `day${day.index}Slots`) > 0
                "
                :color="
                  _get(record, `day${day.index}Vaccine`) !== 0
                    ? '#87d068'
                    : '#f50'
                "
                title="Slots Available"
                class="smallTags"
              >
                <a
                  href="https://selfregistration.cowin.gov.in/"
                  target="_blank"
                  class="vaccineAvailable"
                >
                  {{ _get(record, `day${day.index}Slots`) }} :
                  {{ _get(record, `day${day.index}Vaccine`) }}
                </a>
              </a-tag>

              <a-tag
                v-if="
                  _get(record, `day${day.index}Vaccine`) !== '' &&
                  _get(record, `day${day.index}Slots`) === 0
                "
                color="#ff4400"
                title="Fully Booked"
                class="smallTags"
              >
                Booked : {{ _get(record, `day${day.index}Vaccine`) }}
              </a-tag>

              <div>
                <a-tag
                  class="smallTags"
                  v-if="_get(record, `day${day.index}Vaccine`) !== ''"
                  :color="
                    _get(record, `day${day.index}AgeLimit`) === 45
                      ? '#2db7f5'
                      : '#fa8c16'
                  "
                  title="Age Limit"
                >
                  {{ _get(record, `day${day.index}AgeLimit`) }}+
                </a-tag>

                <a-tag
                  class="smallTags"
                  v-if="_get(record, `day${day.index}dose1`) !== ''"
                  title="Dose 1"
                  color="purple"
                >
                  D1: {{ _get(record, `day${day.index}dose1`) }}
                </a-tag>

                <a-tag
                  class="smallTags"
                  v-if="_get(record, `day${day.index}dose2`) !== ''"
                  title="Dose 2"
                  color="purple"
                >
                  D2: {{ _get(record, `day${day.index}dose2`) }}
                </a-tag>
              </div>

              <a-tag
                v-if="
                  _get(record, `day${day.index}Vaccine`) === '' &&
                  _get(record, `day${day.index}Slots`) === ''
                "
                color="#387eff"
                title="Not Available"
                class="smallTags"
              >
                N/A
              </a-tag> -->
            </div>

            <!-- TODAY Date -->
            <!-- <div slot="today" slot-scope="text, record">
              <a-tag
                v-if="record.todayVaccine !== ''"
                :color="record.todayAgeLimit === 45 ? '#2db7f5' : '#fa8c16'"
                title="Age Limit"
              >
                {{ record.todayAgeLimit }}+
              </a-tag>

              <a-tag
                v-if="record.todayVaccine !== '' && record.todaySlots === 0"
                color="#ff4400"
                title="Fully Booked"
              >
                Booked : {{ record.todayVaccine }}
              </a-tag>

              <a-tag
                v-if="record.todayVaccine === '' && record.todaySlots === 0"
                color="#387eff"
                title="Not Available"
              >
                N/A
              </a-tag>

              <a-tag
                v-if="record.todayVaccine !== '' && record.todaySlots > 0"
                :color="record.todaySlots > 0 ? '#87d068' : 'f50'"
                title="Slots Available"
              >
                <a
                  href="https://selfregistration.cowin.gov.in/"
                  target="_blank"
                >
                  {{ record.todaySlots }} : {{ record.todayVaccine }}
                </a>
              </a-tag>
            </div> -->

            <!-- TOMORROW Date -->
            <!-- <div slot="tomorrow" slot-scope="text, record">
              <a-tag
                v-if="record.tomorrowVaccine !== ''"
                :color="record.tomorrowAgeLimit === 45 ? '#2db7f5' : '#fa8c16'"
                title="Age Limit"
              >
                {{ record.tomorrowAgeLimit }}+
              </a-tag>

              <a-tag
                v-if="
                  record.tomorrowVaccine !== '' && record.tomorrowSlots === 0
                "
                color="#ff4400"
                title="Fully Booked"
              >
                Booked : {{ record.tomorrowVaccine }}
              </a-tag>

              <a-tag
                v-if="
                  record.tomorrowVaccine === '' && record.tomorrowSlots === 0
                "
                color="#387eff"
                title="Not Available"
              >
                N/A
              </a-tag>

              <a-tag
                v-if="record.tomorrowVaccine !== '' && record.tomorrowSlots > 0"
                :color="record.tomorrowSlots > 0 ? '#87d068' : 'f50'"
                title="Slots Available"
              >
                <a
                  href="https://selfregistration.cowin.gov.in/"
                  target="_blank"
                  class="tocowinslotavailable"
                >
                  {{ record.tomorrowSlots }} : {{ record.tomorrowVaccine }}
                </a>
              </a-tag>
            </div> -->

            <!-- Center Name -->
            <div slot="centername" slot-scope="text, record">
              {{ record.name }}
            </div>

            <!-- Address -->
            <div slot="address" slot-scope="text, record">
              {{ record.address }}
            </div>
          </a-table>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      A small effort by
      <a href="https://miteshvanatwala.com" target="_blank">Mitesh Vanatwala</a>
      in order to heal INDIA. (ॐ सर्वे भवन्तु सुखिनः। सर्वे सन्तु निरामयाः।)
    </a-layout-footer>
  </a-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import _nth from "lodash/nth";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import _size from "lodash/size";
import _set from "lodash/set";
import _get from "lodash/get";
import Bell from "@/assets/bell.wav";
import { notification } from "ant-design-vue";
// import dummyData from "./DummyData";

// console.log(dummyData);
const days = 4;
const dateArray = [];
const sortFields = ["day1Slots"];
const todayDate = moment();
const idleObject = {
  day1Slots: "",
  day1Vaccine: "",
  day1AgeLimit: "",
  day1dose1: "",
  day1dose2: "",
};
dateArray.push({
  date: todayDate.format("DD-MM-YYYY"),
  displayDate: todayDate.format("DD MMMM YYYY"),
  index: 1,
});

for (let index = 1, len = days; index < len; index++) {
  todayDate.add("days", 1);
  dateArray.push({
    date: todayDate.format("DD-MM-YYYY"),
    displayDate: todayDate.format("DD MMMM YYYY"),
    index: index + 1,
  });

  _set(idleObject, `day${index + 1}Slots`, "");
  _set(idleObject, `day${index + 1}Vaccine`, "");
  _set(idleObject, `day${index + 1}AgeLimit`, "");
  _set(idleObject, `day${index + 1}dose1`, "");
  _set(idleObject, `day${index + 1}dose2`, "");

  sortFields.push(`day${index + 1}Slots`);
}
// console.log(dateArray);
// console.log(idleObject);
const widthOfEachDay = 47 / _size(dateArray);

const columns = [
  {
    title: "Pincode",
    dataIndex: "pincode",
    key: "pincode",
    width: "9%",
    scopedSlots: {
      customRender: "pincode",
    },
  },
  // {
  //   title: "Today",
  //   key: "Date1",
  //   dataIndex: "Date1",
  //   width: "22%",
  //   align: "left",
  //   ellipsis: false,
  //   scopedSlots: {
  //     customRender: "today",
  //   },
  // },
  // {
  //   title: "Tomorrow",
  //   key: "Date2",
  //   dataIndex: "Date2",
  //   width: "22%",
  //   align: "left",
  //   ellipsis: false,
  //   scopedSlots: {
  //     customRender: "tomorrow",
  //   },
  // },
];

_map(dateArray, ({ date, displayDate, index }) => {
  // console.log(`day${index}`);
  columns.push({
    title: displayDate,
    key: date,
    dataIndex: date,
    width: `${widthOfEachDay}%`,
    ellipsis: false,
    scopedSlots: {
      customRender: `day${index}`,
    },
  });
});

columns.push(
  {
    title: "Center Name",
    key: "name",
    dataIndex: "name",
    width: "20%",
    ellipsis: false,
    scopedSlots: {
      customRender: "centername",
    },
  },
  {
    title: "Address",
    key: "address",
    dataIndex: "address",
    width: "30%",
    ellipsis: false,
    scopedSlots: {
      customRender: "address",
    },
  }
);

const timeInterval = 4000;
export default {
  name: "Vaccine",
  props: {},
  data() {
    return {
      formData: {
        state: undefined,
        district: undefined,
        playSound: true,
        ageOne: true,
        ageTwo: true,
        covishield: true,
        covaxin: true,
        sputnikv: true,
      },
      showDistrict: false,
      showRefreshing: false,
      stateList: [],
      districtList: [],
      allSlots: [],
      filteredSlots: [],
      dateArray: [],
      todayDate: moment().format("DD-MM-YYYY"),
      nextDate: moment().add(1, "days").format("DD-MM-YYYY"),
      columns,
      timeInterval: timeInterval,
      timerObject: "",
      counterObject: "",
      counter: timeInterval / 1000,
      daySlots: dateArray,
    };
  },

  computed: {
    // propertyComputed() {
    //   return this.property;
    // },
  },
  created() {
    this.getStateList();
    // this.dateArray.push(moment().format("DD-MM-YYYY"));
    // this.dateArray.push(moment().add(1, "days").format("DD-MM-YYYY"));
  },
  beforeDestroy() {
    if (this.timerObject !== "") clearInterval(this.timerObject);
    this.clearCounterTimer();
  },
  methods: {
    _get,
    clone(obj) {
      if (null == obj || "object" != typeof obj) return obj;
      var copy = obj.constructor();
      for (var attr in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
      }
      return copy;
    },
    updateAgeFilter(age) {
      if (age === "18") {
        //18
        this.formData.ageOne = !this.formData.ageOne;
      } else {
        // 45
        this.formData.ageTwo = !this.formData.ageTwo;
      }
    },
    updateVaccineFilter(vaccine) {
      if (vaccine === "COVISHIELD")
        this.formData.covishield = !this.formData.covishield;
      else if (vaccine === "COVAXIN")
        this.formData.covaxin = !this.formData.covaxin;
      else if (vaccine === "SPUTNIKV")
        this.formData.sputnikv = !this.formData.sputnikv;
    },
    reduceCounter() {
      if (this.counter > 0) {
        this.counterObject = setInterval(() => {
          this.counter--;
        }, 1000);
      } else {
        this.clearCounterTimer();
      }
    },
    filterState(input, option) {
      return (
        // eslint-disable-next-line
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterDistrict(input, option) {
      return (
        // eslint-disable-next-line
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getStateList: async function () {
      const url = `https://cdn-api.co-vin.in/api/v2/admin/location/states`;
      try {
        const stateDetails = await axios.get(url);
        const { status, data } = stateDetails;
        if (status === 200) this.stateList = data.states;
      } catch (error) {
        console.log(error);
      }
    },
    stateChange: async function (stateId) {
      // Clear interval on change state
      if (this.timerObject !== "") clearInterval(this.timerObject);
      this.clearCounterTimer();

      let url = `https://cdn-api.co-vin.in/api/v2/admin/location/districts/${stateId}`;

      try {
        const districtDetails = await axios.get(url);
        const { status, data } = districtDetails;
        this.showDistrict = false;
        if (status === 200) {
          this.showDistrict = true;
          this.showRefreshing = false;
          this.formData.district = undefined;
          this.filteredSlots = [];
          this.districtList = data.districts;
        }
      } catch (error) {
        console.log(error);
        this.showDistrict = false;
        this.showRefreshing = false;
        this.formData.district = undefined;
        this.districtList = [];
        this.filteredSlots = [];
      }
    },

    districtChange: function () {
      if (this.timerObject !== "") clearInterval(this.timerObject);
      this.clearCounterTimer();

      this.getSlotData();
      this.timerObject = setInterval(() => {
        this.clearCounterTimer();
        this.getSlotData();
      }, this.timeInterval);
    },

    clearCounterTimer() {
      if (this.counterObject !== "") {
        clearInterval(this.counterObject);
        this.counter = timeInterval / 1000;
        this.counterObject = "";
      }
    },

    getDateIndex(date) {
      return dateArray.findIndex((dateObj) => dateObj.date === date) + 1;
    },

    async getSlotData() {
      // this.filteredSlots = [];
      this.showRefreshing = false;
      let isVaccineAvailable = false;
      // NOTE API URL
      const url = `https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/calendarByDistrict?district_id=${
        this.formData.district
      }&date=${this.todayDate}&timestamp=${new Date().getTime()}`;
      // NOTE UPDATE API URL
      // const url = "http://192.168.0.26:3001/";

      try {
        const apiResponse = await axios.get(url);
        const { status, data } = apiResponse;

        if (status === 200) {
          // if (!this.showRefreshing) {
          // const data = dummyData;
          this.showRefreshing = true;
          let allCentersWithSlots = [];
          _map(data.centers, (center) => {
            const {
              center_id,
              pincode,
              name,
              fee_type,
              block_name,
              address,
              sessions,
            } = center;
            let todaySlots = 0;
            let tomorrowSlots = 0;
            let todayAgeLimit = 0;
            let todayVaccine = "";
            let tomorrowVaccine = "";
            let tomorrowAgeLimit = 0;

            // let allDayValues = Object.assign(idleObject, true);
            let allDayValues = this.clone(idleObject);
            let multiVaccine = {};
            _map(
              sessions,
              ({
                session_id,
                date,
                available_capacity,
                vaccine,
                min_age_limit,
                available_capacity_dose1,
                available_capacity_dose2,
              }) => {
                if (
                  (this.formData.ageOne && min_age_limit === 18) ||
                  (this.formData.ageTwo && min_age_limit === 45) ||
                  (this.formData.covishield && vaccine === "COVISHIELD") ||
                  (this.formData.covaxin && vaccine === "COVAXIN") ||
                  (this.formData.sputnikv && vaccine === "SPUTNIK-V")
                ) {
                  if (!multiVaccine[date]) multiVaccine[date] = [];
                  const columnName = this.getDateIndex(date);
                  _set(
                    allDayValues,
                    `day${columnName}Slots`,
                    available_capacity
                  );
                  _set(allDayValues, `day${columnName}Vaccine`, vaccine);
                  _set(allDayValues, `day${columnName}AgeLimit`, min_age_limit);
                  _set(
                    allDayValues,
                    `day${columnName}dose1`,
                    available_capacity_dose1
                  );
                  _set(
                    allDayValues,
                    `day${columnName}dose2`,
                    available_capacity_dose2
                  );

                  multiVaccine[date].push({
                    key: session_id,
                    available_capacity,
                    vaccine,
                    min_age_limit,
                    available_capacity_dose1,
                    available_capacity_dose2,
                  });

                  // if (date === this.todayDate) {
                  //   todaySlots = available_capacity;
                  //   todayVaccine = vaccine;
                  //   todayAgeLimit = min_age_limit;
                  // } else if (date === this.nextDate) {
                  //   tomorrowSlots = available_capacity;
                  //   tomorrowVaccine = vaccine;
                  //   tomorrowAgeLimit = min_age_limit;
                  // }

                  if (available_capacity > 0 && !isVaccineAvailable)
                    isVaccineAvailable = true;
                }
              }
            );
            // NOTE condition only show empty slot records and discard other
            // if (tomorrowSlots) {
            if (_size(multiVaccine)) {
              allCentersWithSlots.push({
                center_id,
                pincode,
                name,
                block_name,
                address,
                fee_type,
                // vaccine: "",
                age: "",
                date: "",
                todaySlots,
                todayVaccine,
                todayAgeLimit,
                tomorrowSlots,
                tomorrowVaccine,
                tomorrowAgeLimit,
                ...allDayValues,
                vaccine: multiVaccine,
              });
            }
            allDayValues = {};
            // }
          });

          // order by desc on "tomorrowSlots"
          allCentersWithSlots = _sortBy(
            allCentersWithSlots,
            sortFields
          ).reverse();

          // FIXME filter age
          // if (this.formData.ageOne != this.formData.ageTwo) {
          //   allCentersWithSlots = allCentersWithSlots.filter((item) => {
          //     if (this.formData.ageOne)
          //       return item.todayAgeLimit == 18 || item.tomorrowAgeLimit == 18;
          //     if (this.formData.ageTwo)
          //       return item.todayAgeLimit == 45 || item.tomorrowAgeLimit == 45;
          //   });
          // }
          // console.log(allCentersWithSlots[0]);
          // Ring bell only if tomorrowSlot available
          // const checkSlotCount = allCentersWithSlots.filter(
          //   (item) => item.tomorrowSlots > 0
          // );

          if (isVaccineAvailable && this.formData.playSound) {
            var tingTongbell = new Audio(Bell);
            tingTongbell.play();
          }

          this.filteredSlots = allCentersWithSlots;
          // FIXME update timer
          // setCenterList(allCentersWithSlots);
          // setSeconds(3);
        } else {
          this.showRefreshing = true;
          notification.error({
            message:
              "Something goes wrong. Please wait for sometime to load the data.",
          });
          this.filteredSlots = [];
        }
        this.clearCounterTimer();
        this.reduceCounter();
      } catch (error) {
        this.showRefreshing = true;
        // notification.error({ message: "Something goes wrong." });
        notification.open({
          message: "Oops... Something goes wrong.",
          description:
            "Please wait for sometime to load the data automatically.",
          icon: <a-icon type="frown" style="color: #f50" />,
          duration: 5,
          placement: "bottomRight",
        });

        this.clearCounterTimer();
        this.reduceCounter();

        // this.showRefreshing = false;
        // in case of getting error or 401
        this.filteredSlots = [];
        //this.formData.district = "";
        console.log(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#components-layout {
  padding: 10px;
  // background: rgba(255, 255, 255, 1);
  .ant-layout {
    padding: 0px 16px;
  }
}
.title {
  font-weight: bold;
  // padding-top: 10px;
}
.divider {
  margin: 13px 0px;
}
.divider.top {
  margin-top: 0px !important;
}
.tag {
  cursor: pointer;
}
.tocowinslotavailable {
  color: #fff;
}
.tocowin {
  background: #ffc002;
  color: #001f60;
  border-color: #001f60;
  padding: 5px 20px;
}
.smallTags {
  padding: 0px 3px;
  line-height: 17px;
  margin-top: 5px;
  margin-right: 5px;
}
.vaccineAvailable {
  color: #fff;
}
.bookedVaccine {
  padding: 0px 3px;
}
</style>
